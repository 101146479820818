@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200&display=swap');

body {
  margin: 0;
  font-family: 'Manrope', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Removing default style */
.ant-input:focus {
  border-color: transparent;
  box-shadow: none;
}

.ant-input-affix-wrapper:focus-within {
  border: 1px solid #666666;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
}

.ant-input-affix-wrapper-disabled{
  background-color: #F5F5F5F5 !important;
}

.ant-picker:focus {
  box-shadow: none !important;
}

.ant-picker-focused.ant-picker{
  border: 1px solid #666666 !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
}

.ant-select-focused .ant-select-selector {
  box-shadow: none !important;
  border-color: #a6a6a6 !important;
  border: 1px solid #a6a6a6 !important;
}

.ant-select-dropdown {
  font-family: 'Manrope', sans-serif;
}

.fc-view,
.fc-event {
  font-family: 'Manrope', sans-serif;
}

.fc-daygrid-event-dot {
  font-family: 'Manrope', sans-serif;
}

.fc-event {
  padding: 5px;
}

.body-no-scroll {
  overflow: hidden;
}