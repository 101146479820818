header {
  width: 100%;
  background-color: #001529;
  padding: 15px;
}

header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60%;
  width: 90%; 
  margin: auto;
}

header img {
  width: 37px;
  margin-right: 0;
  padding: 15px 0;
}

header nav {
  display: flex;
}

header nav ul {
  display: flex;
  margin-right: 60px !important;
}

header nav ul li {
  list-style: none;
}

header nav ul li a.selected {
  background-color: #1678FF;
  border-radius: 5px;
  padding: 8px 12px;
}

header nav ul li a.selected:hover {
  color: white;
}

.logout {
  display: none;
}

header nav ul li a {
  text-decoration: none;
  color: white;
  font-family: 'Manrope', sans-serif;
  font-size: 13.5px;
  letter-spacing: .2vh;
  margin: 15px;
  transition: all 250ms linear 0s;
}

header nav ul li a {
  position: relative;
  animation-name: menu;
  animation-duration: 1s;
  transition: 100ms;
}

@keyframes menu {
  0%   { left:0px; top: -100px;}
  100% { left: 0px; top:0px;}
}

@media (max-width: 630px) {
  html {
    font-size: 70%;
  }

 .hero .container {
   flex-direction: column;
   text-align: center;
 }

 .form-group {
   display: block;
 }
}

@media (max-width: 1285px) {
   .menu-section nav {
    display: none;
  }

  .one,
  .two,
  .three {
    background-color:#ffffff;
    height: 5px;
    width: 100%;
    margin: 6px auto;
    transition-duration: 0.3s;
  }

  .menu-toggle {
    width: 40px;
    height: 30px;
    margin-right: 20px;
  }

  /*Fullscreen*/
  .menu-section.on {
    position: absolute;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #001529;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-section.on nav {
    display: block;
  }

  .logout {
    display: block;
  }

  .dropperView {
    display: none;
  }
  
  .menu-section.on .menu-toggle {
    position: absolute;
    right: 25px;
    top: 25px;
  }
  
  .menu-section.on .menu-toggle .one {
    transform: rotate(45deg) translate(7px, 7px);
    background-color: #fff;
  }

  .menu-section.on .menu-toggle .two {
   opacity: 0;

  }

  .menu-section.on .menu-toggle .three {
    transform: rotate(-45deg) translate(8px, -9px);
    background-color: #fff;
  }

  .menu-section.on nav ul {
    text-align: center;
    display: block;
  }

  .menu-section.on nav ul a { 
    transition-duration:  0.5s;
    font-size: 2.0rem;
    display: block;
    color: #fff;
    padding: 1.8rem;
  }

  .menu-section.on nav ul .hide { 
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 0rem;
     font-size: 1.6rem;
   }
}

@media (max-width: 320px) {
  #form h3 {
    font-size: 2rem;
  }
}

/* calendar style */
.fc-day-today {
  background-color: inherit !important;
}